import React, { useState, useEffect } from 'react';
import './FloatingButton.css';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const FloatingButton = () => {
    const [huntSignedUp, setHuntSignedUp] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('CA');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handlePhoneNumberChange = (e) => {
        const input = e.target.value.replace(/\D/g, '').slice(0, 10);
        setPhoneNumber(input);
    };

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const scrollPercent = (scrollTop + windowHeight) / documentHeight;

            if (!huntSignedUp && !showModal) {
                if (scrollPercent < 0.90) {
                    setShowButton(true);
                } else {
                    setShowButton(false);
                }
            } else {
                setShowButton(false);
            }
        };

        const timer = setTimeout(() => {
            window.addEventListener('scroll', handleScroll);
            handleScroll();
        }, 4000);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [huntSignedUp, showModal]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (phoneNumber.length !== 10) {
            alert('Please enter exactly 10 digits for the phone number.');
            return;
        }

        setIsSubmitting(true);

        try {
            const countryCode = countryCodes.find(code => code.id === selectedCountry).value;
            const fullPhoneNumber = `${countryCode}${phoneNumber}`;
            const docRef = await addDoc(collection(db, "scavengerHunt"), {
                firstName: firstName,
                lastName: lastName,
                phoneNumber: fullPhoneNumber
            });
            console.log("Document written with ID: ", docRef.id);

            const functions = getFunctions();
            const sendSMS = httpsCallable(functions, 'sendSMS');
            await sendSMS({ 
                to: fullPhoneNumber, 
                name: firstName 
            }).then(result => {
                console.log("SMS sent successfully:", result.data);
            }).catch(error => {
                console.error("Error sending SMS:", error);
            });

            setHuntSignedUp(true);
            setShowModal(false);
            setShowButton(true); // Show button immediately after successful submission
            alert('You have successfully signed up for the scavenger hunt!');
        } catch (error) {
            console.error("Error adding document: ", error);
            alert('There was an error signing you up. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCloseModal = () => {
        const modalContent = document.querySelector('.floatingbutton-modal-content');
        if (modalContent) {
            modalContent.classList.add('floatingbutton-modal-exit');
        }
        setShowModal(false); // Close the modal immediately
        setShowButton(true); // Show the button immediately after closing the modal
        if (modalContent) {
            modalContent.addEventListener('animationend', () => {
                modalContent.classList.remove('floatingbutton-modal-exit');
            }, { once: true });
        }
    };

    // Toggle modal and button visibility instantly
    const toggleModal = () => {
        if (showModal) {
            handleCloseModal(); // This will close the modal and show the button
        } else {
            setShowModal(true);
            setShowButton(false); // Hide button when modal opens
        }
    };

    const countryCodes = [
        { id: 'CA', label: 'Canada (+1)', value: '+1' },
        // Add more countries as needed
    ];

    return (
        <>
            {showButton && (
                <button 
                    className={`floatingbutton-button ${showButton ? 'floatingbutton-visible' : 'floatingbutton-hidden'}`} 
                    onClick={toggleModal}
                >
                    <span className="floatingbutton-header">Cafe Scavenger Hunt</span>
                    <span className="floatingbutton-subtext">happening in Vancouver</span>
                    <span className="floatingbutton-subtext">sign up for the first clue</span>
                </button>
            )}

            {showModal && (
                <div className="floatingbutton-modal">
                    <div className="floatingbutton-modal-content">
                        <span className="floatingbutton-close" onClick={toggleModal}>×</span>
                        <form onSubmit={handleSubmit} className="floatingbutton-form">
                            <select 
                                value={selectedCountry} 
                                onChange={(e) => setSelectedCountry(e.target.value)}
                                required
                                disabled={isSubmitting}
                            >
                                {countryCodes.map((code) => (
                                    <option key={code.id} value={code.id}>
                                        {code.label}
                                    </option>
                                ))}
                            </select>
                            <input 
                                type="tel" 
                                placeholder="Phone Number (10 digits)" 
                                value={phoneNumber} 
                                onChange={handlePhoneNumberChange}
                                required 
                                maxLength="10"
                                disabled={isSubmitting}
                            />
                            <input 
                                type="text" 
                                placeholder="First Name" 
                                value={firstName} 
                                onChange={(e) => setFirstName(e.target.value)}
                                required 
                                disabled={isSubmitting}
                            />
                            <input 
                                type="text" 
                                placeholder="Last Name" 
                                value={lastName} 
                                onChange={(e) => setLastName(e.target.value)}
                                required 
                                disabled={isSubmitting}
                            />
                            <button 
                                type="submit" 
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default FloatingButton;